import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div
        className="login-page"
        style={{ backgroundImage: `url(img/bg-login.jpg)` }}
      >
        <div>
          <img alt="Splash" src="img/splash.png" />
          <div>
            For information contact{" "}
            <strong>
              <a href="mailto:info@webf1.com">info@webf1.com</a>
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
